<template>
  <div class="holder">
    <h1>
      分时主图
      <span @click="playInTime('timeChart')">入场</span>
      <span @click="playOut('timeChart')">出场</span>
    </h1>
    <time-chart ref="timeChart" id="timeChart" customClass="c_line1" />
    
    <h1>
      K线主图
      <span @click="playInDayK('dayKChart')">入场</span>
      <span @click="playOut('dayKChart')">出场</span>
    </h1>
    <k-chart ref="dayKChart" id="dayKChart" customClass="c_line1" />


    <h1>
      分时成交量
      <span @click="playIn1('volumeChart')">入场</span>
      <span @click="playOut('volumeChart')">出场</span>
    </h1>
    <sub-chart ref="volumeChart" id="volumeChart" customClass="c_line1" />

    <h1>
      分时量比
      <span @click="playIn2('volumeRatioChart')">入场</span>
      <span @click="playOut('volumeRatioChart')">出场</span>
    </h1>
    <sub-chart
      ref="volumeRatioChart"
      id="volumeRatioChart"
      customClass="c_line1"
    />

    <h1>
      分时macd
      <span @click="playIn3('macdChart')">入场</span>
      <span @click="playOut('macdChart')">出场</span>
    </h1>
    <sub-chart ref="macdChart" id="macdChart" customClass="c_line1" />

    <h1>
      分时资金博弈
      <span @click="playIn4('fundsChart')">入场</span>
      <span @click="playOut('fundsChart')">出场</span>
    </h1>
    <sub-chart ref="fundsChart" id="fundsChart" customClass="c_line1" />

    <h1>
      k线成交量
      <span @click="playIn5('kVolumeChart')">入场</span>
      <span @click="playOut('kVolumeChart')">出场</span>
    </h1>
    <sub-chart ref="kVolumeChart" id="kVolumeChart" customClass="c_line1" />

    <h1>
      k线主力资金
      <span @click="playIn6('kCapitalChart')">入场</span>
      <span @click="playOut('kCapitalChart')">出场</span>
    </h1>
    <sub-chart
      ref="kCapitalChart"
      id="kCapitalChart"
      customClass="c_line1"
    />
  </div>
</template>
<script>
import kChart from "@/components/marketChart/kChart.vue";
import timeChart from "@/components/marketChart/timeChart.vue";
import subChart from "@/components/marketChart/subChart.vue";
import kLineData from "./kData.json";
import timeData from "./timeData.json";
export default {
  data: function () {
    return {
  
    };
  },
  components: { subChart,kChart,timeChart },
  methods: {
    playInTime(refName) {
      this.$refs[refName].TimeChart(timeData, {
        width: 600,
        height: 300,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 50,
        duration: 1000,
        delay: 40,
        ease: "easeCubicInOut",
        xTicks: ["09:30", "11:30/13:00", "15:00"],
      });
    },
    playInDayK(refName) {
      this.$refs[refName].KLineChart(kLineData, {
        width: 600,
        height: 300,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 50,
        duration: 1000,
        delay: 40,
        ease: "easeLinear",
      });
    },
    playIn1(refName) {
      this.$refs[refName].timeVolumeChart(timeData, {
        width: 600,
        height: 300,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 50,
        duration: 1000,
        delay: 40,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
        xTicks: ["09:30", "11:30/13:00", "15:00"],
      });
    },

    playIn2(refName) {
      this.$refs[refName].timeVolumeRatioChart(timeData, {
        width: 600,
        height: 300,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 50,
        duration: 1000,
        delay: 40,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
      });
    },

    playIn3(refName) {
      this.$refs[refName].timeMacdChart(timeData.data, {
        width: 600,
        height: 300,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 50,
        duration: 1000,
        delay: 40,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
        isTicks: true, //只有画k线macd是才传
      });
    },

    playIn4(refName) {
      this.$refs[refName].fundsFight(timeData, {
        width: 600,
        height: 300,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 50,
        duration: 1000,
        delay: 40,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
      });
    },

    playIn5(refName) {
      this.$refs[refName].kVolumeChart(kLineData, {
        width: 600,
        height: 300,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 50,
        duration: 1000,
        delay: 40,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
        xTicks: true,
      });
    },

    playIn6(refName) {
      this.$refs[refName].kCapitalChart(kLineData, {
        width: 600,
        height: 300,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 50,
        duration: 1000,
        delay: 40,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
        xTicks: true,
      });
    },

    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h1 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h2 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  .new {
    color: #fff;
    background: #f00;
    padding: 0.5rem;
    border-radius: 0.3125rem;
    margin-right: 0.625rem;
  }
}
p {
  margin-bottom: 20px;
}

.c_line1,
.c_line11 {
  margin-bottom: 20px;
  min-height: 200px;
  background: #fff;
  // background: -webkit-linear-gradient(top right, #e5884f, #e16906);
  // background: -moz-linear-gradient(top right, #e5884f, #e16906);
  // background: linear-gradient(to bottom left, #e5884f, #e16906);
  display: inline-block;
  /deep/ .label_min {
    font-size: 14px;
    fill: #fff;
  }
  /deep/ .label_min_bg {
    fill: #e24c4c;
  }
  /deep/ .circle_min {
    fill: rgba(226, 76, 76, 0.692);
  }
  /deep/ .label_max {
    font-size: 14px;
    fill: #fff;
  }
  /deep/ .label_max_bg {
    fill: #0b777a;
  }
  /deep/ .circle_max {
    fill: rgba(11, 118, 122, 0.712);
  }
  /deep/ .axis_x,
  /deep/ .axis_y {
    .text {
      fill: #fff;
      font-size: 14px;
    }
    .domain,
    .tick_line {
      stroke: #fff;
    }
    .tick_long_line {
      display: none;
    }
  }
}

.c_line2 {
  margin-bottom: 20px;
  min-height: 200px;
  background: #bfe54f;
  background: -webkit-linear-gradient(top right, #bfe54f, #a3a829);
  background: -moz-linear-gradient(top right, #bfe54f, #a3a829);
  background: linear-gradient(to bottom left, #bfe54f, #a3a829);
  display: inline-block;
  /deep/ .label_min {
    font-size: 14px;
    fill: #fff;
  }
  /deep/ .label_min_bg {
    fill: #e24c4c;
  }
  /deep/ .circle_min {
    fill: rgba(226, 76, 76, 0.692);
  }
  /deep/ .label_max {
    font-size: 14px;
    fill: #fff;
  }
  /deep/ .label_max_bg {
    fill: #0b777a;
  }
  /deep/ .circle_max {
    fill: rgba(11, 118, 122, 0.712);
  }
  /deep/ .line1 {
    stroke: #fefefe;
    stroke-width: 3px;
  }
  /deep/ .line2 {
    stroke: #81195c;
    stroke-width: 3px;
  }
  /deep/ .axis_x,
  /deep/ .axis_y {
    .text {
      fill: #fff;
      font-size: 14px;
    }
    .domain,
    .tick_line {
      stroke: #fff;
    }
    .tick_line {
      display: none;
    }
    .tick_long_line {
      stroke: rgba(255, 255, 255, 0.15);
    }
  }
}

.c_line3 {
  margin-bottom: 20px;
  min-height: 200px;
  background: #4fd8e5;
  background: -webkit-linear-gradient(top right, #4fd8e5, #297aa8);
  background: -moz-linear-gradient(top right, #4fd8e5, #297aa8);
  background: linear-gradient(to bottom left, #4fd8e5, #297aa8);
  display: inline-block;
  /deep/ .line1 {
    stroke: #fefefe;
    stroke-width: 3px;
  }
  /deep/ .line2 {
    stroke: #81195c;
    stroke-width: 3px;
  }
  /deep/ .axis_x,
  /deep/ .axis_y {
    .text {
      fill: #fff;
      font-size: 14px;
    }
    .domain,
    .tick_line {
      display: none;
    }
  }
}

.c_line11 {
  /deep/ .circle1 {
    fill: #fefefe;
  }
  /deep/ .valueTip1 {
    fill: #fefefe;
  }
  /deep/ .circle2 {
    fill: #81195c;
  }
  /deep/ .valueTip2 {
    fill: #81195c;
  }
  /deep/ .circle3 {
    fill: red;
  }
  /deep/ .valueTip3 {
    fill: red;
  }
  /deep/ .line3 {
    stroke: red;
    stroke-width: 3px;
  }
}
.c_line12 {
  margin-bottom: 20px;
  min-height: 200px;
}
</style>
