<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotalArr: [],
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg
        .selectAll(`.y_tick,.x_tick,.y_tick_value,.x_tick_value ,.line,.line_area`)
        .transition()
        .duration(100)
        .style("opacity", "0");
      await sleep(150);
      $("#" + this.id).html("");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    async TimeChart(
      data,
      {
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        totalCount = 241,
        xTicks = [],
      } = {}
    ) {
      this.duration = duration;
      const curve = d3[curveName];
      const fClose = data.fPreClose; //前一个工作日的收盘价
      const timeData = data.data;
      // console.log("timeData", timeData);
      const X1 = timeData.map((d) => d.label);
      const xArr = [];
      for (let i = 570; i <= 690; i++) {
        xArr.push(i);
      }
      for (let i = 781; i <= 900; i++) {
        xArr.push(i);
      }
      const X = xArr.map(
        (d) =>
          (parseInt(d / 60) < 10 ? "0" + parseInt(d / 60) : parseInt(d / 60)) +
          "：" +
          (d % 60 < 10 ? "0" + (d % 60) : d % 60)
      );
      // console.log('X',X);
      const I = d3.range(X.length);

      const timeMax = data.fMax;
      const timeMin = data.fMin;
      const yArr = [];

      if (Math.abs(timeMax - fClose) >= Math.abs(timeMin - fClose)) {
        yArr.push(timeMax.toFixed(2));
        yArr.push(fClose.toFixed(2));
        yArr.push((fClose - (timeMax - fClose)).toFixed(2));
      } else {
        yArr.push((fClose + Math.abs(timeMin - fClose)).toFixed(2));
        yArr.push(fClose.toFixed(2));
        yArr.push(timeMin.toFixed(2));
      }

      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [d3.min(yArr) * 1, d3.max(yArr) * 1];

      const xRange1 = [
        marginLeft,
        ((width - marginRight - marginLeft) * timeData.length) / totalCount + marginLeft,
      ];
      const xDomain1 = X1;
      const xScale = d3.scalePoint(xDomain1, xRange1);
      // const xScale = d3.scalePoint(xDomain, xRange);
      const yScale = d3.scaleLinear(yDomain, yRange);
      const yMagnify = d3.scaleLinear().domain(yRange).range(yDomain); //计算y轴坐标和value对应关系 yMagnify(y)=>value

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      const yTick = [1, 2, 3, 4];
      const xTick = [1, 2, 3, 4, 5, 6, 7];
      const yTickSpace = (height - marginTop - marginBottom) / (yTick.length - 1);
      const xTickSpace = (width - marginLeft - marginRight) / (xTick.length - 1);
      //画网格
      const drawGrid = () => {
        const rowLine = svg
          .append("g")
          .attr("class", "y_tick_group")
          .selectAll("line")
          .data(yTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft)
          .attr("y1", (d, i) => marginTop + yTickSpace * i)
          .attr("x2", (d, i) => marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i)
          .attr("class", "y_tick")
          .attr("stroke", "#F0F0F0")
          .attr("stroke-width", 2)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => width - marginRight)
          .attr("y2", (d, i) => marginTop + yTickSpace * i);

        const columnLine = svg
          .append("g")
          .attr("class", "x_tick_group")
          .selectAll("line")
          .data(xTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft + xTickSpace * i)
          .attr("y1", (d, i) => height - marginBottom)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => height - marginBottom)
          .attr("class", "x_tick")
          .attr("stroke", "#F0F0F0") //F0F0F0
          .attr("stroke-width", 2)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => marginTop);
      };
      drawGrid();

      //画xy轴数据
      const drawXYTicks = () => {
        const yTick = svg
          .append("g")
          .attr("class", "y_tick_group")
          .selectAll("text")
          .data(yArr)
          .enter()
          .append("text")
          .attr("class", (d, i) => {
            if (i == 0) {
              return " y_tick_value y_tick_max";
            } else if (i == 1) {
              return " y_tick_value y_tick_middle";
            } else if (i == 2) {
              return " y_tick_value y_tick_min";
            }
          })
          .text((d) => d)
          .attr("x", marginLeft + 5)
          .attr("y", (d, i) => {
            if (i == 0) {
              return marginTop;
            } else if (i == 1) {
              return (height - marginBottom - marginTop) / 2 + marginTop;
            } else if (i == 2) {
              return height - marginBottom;
            }
          })
          .attr("text-anchor", "start")
          .attr("dy", (d, i) => {
            if (i == 0) {
              return "1.2em";
            } else if (i == 1) {
              return "0.5em";
            } else if (i == 2) {
              return -5;
            }
          })
          .attr("fill", (d, i) => (i == 0 ? "#FF3B30" : i == 2 ? "#18AA0C" : "#666"))
          .attr("opacity", "0")
          .transition()
          .duration(duration / 2)
          .attr("opacity", "1");

        const ratio = (((yArr[0] - fClose) / fClose) * 100).toFixed(2);
        const yArr2 = [ratio, -ratio];
        const yTick2 = svg
          .append("g")
          .attr("class", "y_tick_group2")
          .selectAll("text")
          .data(yArr2)
          .enter()
          .append("text")
          .attr("class", (d, i) => (i == 0 ? "y_tick_value y_tick_max" : "y_tick_value y_tick_min"))
          .text((d) => d + "%")
          .attr("x", width - marginRight - 5)
          .attr("y", (d, i) => {
            if (i == 0) {
              return marginTop;
            } else if (i == 1) {
              return height - marginBottom;
            }
          })
          .attr("text-anchor", "end")
          .attr("dy", (d, i) => {
            if (i == 0) {
              return "1.2em";
            } else if (i == 1) {
              return -5;
            }
          })
          .attr("fill", (d, i) => (i == 0 ? "#FF3B30" : "#18AA0C"))
          .attr("opacity", "0")
          .transition()
          .duration(duration / 2)
          .attr("opacity", "1");

        const xTick = svg
          .append("g")
          .attr("class", "x_tick_group")
          .selectAll("text")
          .data(xTicks)
          .enter()
          .append("text")
          .attr("class", (d, i) => (i == 0 ? "x_tick_value x_tick_max" : "x_tick_value x_tick_min"))
          .text((d) => d)
          .attr("x", (d, i) => {
            if (i == 0) {
              return marginLeft;
            } else if (i == xTicks.length - 1) {
              return width - marginRight;
            } else {
              return marginLeft + ((width - marginRight - marginLeft) / (xTicks.length - 1)) * i;
            }
          })
          .attr("y", height - marginBottom)
          .attr("text-anchor", (d, i) => {
            if (i == 0) {
              return "start";
            } else if (i == xTicks.length - 1) {
              return "end";
            } else {
              return "middle";
            }
          })
          .attr("dy", "1.2em")
          .attr("opacity", "0")
          .transition()
          .duration(duration / 2)
          .attr("opacity", "1");
      };
      drawXYTicks();

      //画线
      const drawLine = () => {
        const lineData = [timeData.map((d) => d.now), timeData.map((d) => d.average)];
        for (let j = 0; j < 2; j++) {
          const Y = lineData[j];
          const pathLine = d3
            .line()
            .curve(curve)
            .x((i) => xScale(X1[i]))
            .y((i) => yScale(Y[i]));

          const svgLine = svg
            .append("path")
            .attr("fill", "none")
            .attr("class", (d) => (j == 0 ? `line now_line` : "line average_line"))
            .attr("stroke", (d) => (j == 0 ? "#178CEA" : "#FFC100"))
            // .attr("stroke-width", "1")
            .attr("d", pathLine(d3.range(Y.length)));
          const svgLineTotalLength = svgLine.node().getTotalLength();
          this.svgLineTotalArr[j] = svgLineTotalLength;
          svgLine
            .attr("stroke-dasharray", svgLineTotalLength + "," + svgLineTotalLength)
            .attr("stroke-dashoffset", svgLineTotalLength)
            .transition()
            .duration(duration)
            .ease(d3[ease])
            .attr("stroke-dashoffset", 0);
        }
      };
      drawLine();

      //画面积
      const drawArea = () => {
        var area = d3
          .area()
          .x(function (d, i) {
            return xScale(d.label);
          }) //对x轴进行缩放
          .y0(height - marginBottom) //定义y0轴
          .y1(function (d) {
            return yScale(d.now);
          }); //对y1轴进行缩放

        //画渐变
        const areaGroup = svg.append("g").attr("class", "area_group");
        const defs = areaGroup.append("defs");
        const linearGradient = defs
          .append("linearGradient")
          .attr("id", "linearColor")
          .attr("x1", "0%")
          .attr("y1", "0%")
          .attr("x2", "0%")
          .attr("y2", "100%");
        linearGradient
          .append("stop")
          .attr("offset", "0%")
          .style("stop-color", "#178CEA")
          .style("stop-opacity", "0.42");
        linearGradient
          .append("stop")
          .style("stop-color", "#178CEA")
          .attr("offset", "100%")
          .style("stop-opacity", "0.16");

        //画区域图
        areaGroup
          .append("path")
          .attr("d", area([timeData[0]]))
          .attr("class", "line_area")
          .attr("fill", "url(#" + linearGradient.attr("id") + ")")
          .transition()
          .duration(duration)
          .attrTween("d", () => {
            let index = d3.interpolate(0, timeData.length - 1);
            return (t) => {
              let deg = Math.round(index(t));
              // console.log(deg);
              return area(timeData.slice(0, deg + 1));
            };
          });
      };
      drawArea();

      //画最后一个点
      const theLastItem = timeData[timeData.length - 1];
      const theLastPoint = svg
        .append("circle")
        .attr("class", "the_last_point")
        .attr("cx", xScale(theLastItem.label))
        .attr("cy", yScale(theLastItem.now))
        .attr("r", 5)
        .attr("stroke-width", 9)
        .attr("stroke", "rgba(23, 140, 234, 0.5)")
        .attr("fill", "#178CEA")
        .attr("opacity", 0)
        .transition()
        .delay(duration == 0 ? 0 : 1400)
        .duration(duration == 0 ? 0 : 200)
        .attr("opacity", 1);

      //画起爆点
      const drawDetonate = (index) => {
        const lockData = timeData[index];
        const detonateGroup = svg.append("g").attr("class", "detonate_group");
        const detonateCircle = detonateGroup
          .append("circle")
          .attr("class", "detonate_point")
          .attr("cx", xScale(lockData.label))
          .attr("cy", yScale(lockData.lockValue))
          .attr("r", 6)
          .attr("stroke-width", 8)
          .attr("stroke", "rgba(255, 59, 48, 0.5)")
          .attr("fill", "#FF3B30")
          .attr("opacity", 0)
          .transition()
          .delay(duration == 0 ? 0 : 800)
          .duration(duration == 0 ? 0 : 200)
          .attr("opacity", 1);

        const detonateLine = detonateGroup
          .append("line")
          .attr("class", "detonate_line")
          .attr("x1", xScale(lockData.label))
          .attr("y1", yScale(lockData.lockValue))
          .attr("x2", xScale(lockData.label))
          .attr("y2", yScale(lockData.lockValue))
          .attr("stroke-dasharray", "3,3")
          .attr("stroke-width", 3)
          .attr("stroke", "#FF3B30")
          .transition()
          .delay(duration == 0 ? 0 : 800)
          .duration(duration == 0 ? 0 : 200)
          .attr("y2", yScale(lockData.lockValue) + 25);

        const detonateRect = detonateGroup
          .append("rect")
          .attr("class", "detonate_rect")
          .attr("x", xScale(lockData.label) - 35)
          .attr("y", yScale(lockData.lockValue) + 25)
          .attr("width", 70)
          .attr("height", 34)
          .attr("rx", 12)
          .attr("fill", "#FF3B30")
          .attr("opacity", 0)
          .transition()
          .delay(duration == 0 ? 0 : 800)
          .duration(duration == 0 ? 0 : 200)
          .attr("opacity", 1);

        const detonateText = detonateGroup
          .append("text")
          .attr("class", "detonate_text")
          .attr("x", xScale(lockData.label))
          .attr("y", yScale(lockData.lockValue) + 25)
          .attr("dy", "1.1em")
          .text("锁定")
          .attr("text-anchor", "middle")
          .attr("font-size", "22px")
          .attr("fill", "#fff")
          .attr("opacity", 0)
          .transition()
          .delay(duration == 0 ? 0 : 800)
          .duration(duration == 0 ? 0 : 200)
          .attr("opacity", 1);
      };
      const detonateIndex = timeData.findIndex(
        (d) => Object.prototype.toString.call(d.lockValue) !== "[object Undefined]"
      );
      if (detonateIndex !== -1) {
        drawDetonate(detonateIndex);
      }

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
