<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotal: 0,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },

    //分时成交量
    async timeVolumeChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop + 20], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        totalCount = 241,
        xPadding = 0.3,
        xTicks = [],
      } = {}
    ) {
      const getCurrentDate = () => {
        let date = new Date();
        let year = date.getFullYear();
        let month =
          date.getMonth() + 1 > 10
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1);
        let day = date.getDate() > 10 ? date.getDate() : "0" + date.getDate();
        return year + "/" + month + "/" + day;
      };
      this.duration = duration;

      const curve = d3[curveName];
      const timeData = data.data;
      const X = timeData.map((d) => d.label);
      const volumeMax = d3.max(timeData, (d) => Math.abs(d.volume));
      const volumeMin = d3.min(timeData, (d) => Math.abs(d.volume));

      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [0, volumeMax];

      xRange = [
        marginLeft,
        ((width - marginRight - marginLeft) * timeData.length) / totalCount +
          marginLeft,
      ];
      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);
      const yMagnify = d3.scaleLinear().domain(yRange).range(yDomain); //计算y轴坐标和value对应关系 yMagnify(y)=>value

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      const yTick = [1, 2, 3];
      const xTick = [1, 2, 3, 4, 5];
      const yTickSpace =
        (height - marginTop - marginBottom) / (yTick.length - 1);
      const xTickSpace =
        (width - marginLeft - marginRight) / (xTick.length - 1);
      //画网格
      const drawGrid = () => {
        const rowLine = svg
          .append("g")
          .attr("class", "y_tick_group")
          .selectAll("line")
          .data(yTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft)
          .attr("y1", (d, i) => marginTop + yTickSpace * i)
          .attr("x2", (d, i) => marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i)
          .attr("class", "y_tick")
          .attr("stroke", "#F0F0F0")
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => width - marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i);

        const columnLine = svg
          .append("g")
          .attr("class", "x_tick_group")
          .selectAll("line")
          .data(xTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft + xTickSpace * i)
          .attr("y1", (d, i) => height - marginBottom)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => height - marginBottom)
          .attr("class", "x_tick")
          .attr("stroke", "#F0F0F0") //F0F0F0
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => marginTop);
      };
      drawGrid();

      //画标题
      const drawTitle = () => {
        const titleGroup = svg
          .append("g")
          .attr("class", "title_text")
          .append("text")
          .attr("text", "volume_text")
          .attr("fill", "currentColor")
          .attr("x", marginLeft + 5)
          .attr("y", marginTop)
          .text("成交量 " + timeData[timeData.length - 1].volume)
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
      };
      drawTitle();

      //画柱子
      const drawBar = () => {
        const barGroup = svg
          .append("g")
          .attr("class", "bar_group")
          .selectAll("rect")
          .data(timeData)
          .enter()
          .append("rect")
          .attr("x", (d, i) => xScale(d.label))
          .attr("y", (d, i) => yScale(Math.abs(d.volume)))
          .attr("width", (d, i) => xScale.bandwidth())
          .attr("height", (d, i) => yScale(0) - yScale(Math.abs(d.volume)))
          .attr("class", (d) =>
            d.volume > 0 ? "volume volume_in" : "volume volume_out"
          )
          .attr("fill", (d) =>
            d.volume > 0 ? "#FF3B30" : d.volume <= 0 ? "#18AA0C" : "#666"
          )
          .attr("opacity", 0)
          .transition()
          .delay((d, i) => i * delay)
          .duration(duration)
          .attrTween("opacity", (d, i, a) => {
            let index = d3.interpolate(0, timeData.length - 1);
            return (t) => {
              let num = Math.round(index(t));
              return i <= num ? 1 : 0;
            };
          });
      };
      drawBar();

      const drawXAxisTime = () => {
        const xAxisGroup = svg
          .append("g")
          .attr("class", "x_axis_group")
          .selectAll("text")
          .data(xTicks)
          .enter()
          .append("text")
          .attr("fill", "currentColor")
          .attr("x", (d, i) => {
            if (i == 0) {
              return marginLeft;
            } else if (i == xTicks.length - 1) {
              return width - marginRight;
            } else {
              return (
                marginLeft +
                ((width - marginRight - marginLeft) / (xTicks.length - 1)) * i
              );
            }
          })
          .attr("y", height - marginBottom)
          .text((d) => d)
          .attr("dy", "1.2em")
          .attr("text-anchor", (d, i) => {
            if (i == 0) {
              return "start";
            } else if (i == xTicks.length - 1) {
              return "end";
            } else {
              return "middle";
            }
          })
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
      };
      drawXAxisTime();

      $("#" + this.id).html(svg.node());
    },

    //分时量比
    async timeVolumeRatioChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop + 20], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        totalCount = 241,
        xTicks = [],
      } = {}
    ) {
      this.duration = duration;

      const curve = d3[curveName];
      const timeData = data.data;
      const X = timeData.map((d) => d.label);
      const yMax = d3.max(timeData, (d) => Math.abs(d.volumeRatio));
      const yMin = d3.min(timeData, (d) => Math.abs(d.volumeRatio));

      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [0, yMax];

      xRange = [
        marginLeft,
        ((width - marginRight - marginLeft) * timeData.length) / totalCount +
          marginLeft,
      ];
      const xScale = d3.scalePoint(xDomain, xRange);
      const yScale = d3.scaleLinear(yDomain, yRange);
      const yMagnify = d3.scaleLinear().domain(yRange).range(yDomain); //计算y轴坐标和value对应关系 yMagnify(y)=>value

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      const yTick = [1, 2, 3];
      const xTick = [1, 2, 3, 4, 5];
      const yTickSpace =
        (height - marginTop - marginBottom) / (yTick.length - 1);
      const xTickSpace =
        (width - marginLeft - marginRight) / (xTick.length - 1);
      //画网格
      const drawGrid = () => {
        const rowLine = svg
          .append("g")
          .attr("class", "y_tick_group")
          .selectAll("line")
          .data(yTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft)
          .attr("y1", (d, i) => marginTop + yTickSpace * i)
          .attr("x2", (d, i) => marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i)
          .attr("class", "y_tick")
          .attr("stroke", "#F0F0F0")
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => width - marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i);

        const columnLine = svg
          .append("g")
          .attr("class", "x_tick_group")
          .selectAll("line")
          .data(xTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft + xTickSpace * i)
          .attr("y1", (d, i) => height - marginBottom)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => height - marginBottom)
          .attr("class", "x_tick")
          .attr("stroke", "#F0F0F0") //F0F0F0
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => marginTop);
      };
      drawGrid();

      //画标题
      const drawTitle = () => {
        const titleGroup = svg
          .append("g")
          .attr("class", "title_text")
          .append("text")
          .attr("class", "volume_ratio_text")
          .attr("x", marginLeft + 5)
          .attr("y", marginTop)
          .text("量比 " + timeData[timeData.length - 1].volumeRatio)
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
      };
      drawTitle();

      //画量比线
      const drawLine = () => {
        const Y = timeData.map((d) => d.volumeRatio);
        const pathLine = d3
          .line()
          .curve(curve)
          .x((i) => xScale(X[i]))
          .y((i) => yScale(Y[i]));

        const ratioLine = svg
          .append("g")
          .attr("class", "ratio_line")
          .append("path")
          .attr("fill", "none")
          .attr("class", "line")
          .attr("stroke", "#178CEA")
          .attr("stroke-width", "1")
          .attr("d", pathLine(d3.range(Y.length)));
        const svgLineTotalLength = ratioLine.node().getTotalLength();
        this.svgLineTotal = svgLineTotalLength;
        ratioLine
          .attr(
            "stroke-dasharray",
            svgLineTotalLength + "," + svgLineTotalLength
          )
          .attr("stroke-dashoffset", svgLineTotalLength)
          .transition()
          .duration(duration)
          .ease(d3[ease])
          .attr("stroke-dashoffset", 0);
      };
      drawLine();

      //画x轴时间
      const drawXAxisTime = () => {
        const xAxisGroup = svg
          .append("g")
          .attr("class", "x_axis_group")
          .selectAll("text")
          .data(xTicks)
          .enter()
          .append("text")
          .attr("fill", "currentColor")
          .attr("x", (d, i) => {
            if (i == 0) {
              return marginLeft;
            } else if (i == xTicks.length - 1) {
              return width - marginRight;
            } else {
              return (
                marginLeft +
                ((width - marginRight - marginLeft) / (xTicks.length - 1)) * i
              );
            }
          })
          .attr("y", height - marginBottom)
          .text((d) => d)
          .attr("dy", "1.2em")
          .attr("text-anchor", (d, i) => {
            if (i == 0) {
              return "start";
            } else if (i == xTicks.length - 1) {
              return "end";
            } else {
              return "middle";
            }
          })
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
      };
      drawXAxisTime();

      $("#" + this.id).html(svg.node());
    },

    //分时macd
    async timeMacdChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop + 20], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        totalCount = 241,
        xTicks = [],
        isTicks = false,
        xPadding = 0.3,
      } = {}
    ) {
      this.duration = duration;

      const dataLen = data.length;

      const curve = d3[curveName];
      const timeData = data;
      const X = timeData.map((d) => d.label);
      const yMaxArr = [
        d3.max(timeData, (d) => d.macd.diff),
        d3.max(timeData, (d) => d.macd.dea),
        d3.max(timeData, (d) => d.macd.macd),
      ];
      const yMinArr = [
        d3.min(timeData, (d) => d.macd.diff),
        d3.min(timeData, (d) => d.macd.dea),
        d3.min(timeData, (d) => d.macd.macd),
      ];

      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [d3.min(yMinArr), d3.max(yMaxArr)];

      xRange = [
        marginLeft,
        ((width - marginRight - marginLeft) * timeData.length) / totalCount +
          marginLeft,
      ];
      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);
      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      const yTick = [1, 2, 3];
      const xTick = [1, 2, 3, 4, 5];
      const yTickSpace =
        (height - marginTop - marginBottom) / (yTick.length - 1);
      const xTickSpace =
        (width - marginLeft - marginRight) / (xTick.length - 1);
      //画网格
      const drawGrid = () => {
        const rowLine = svg
          .append("g")
          .attr("class", "y_tick_group")
          .selectAll("line")
          .data(yTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft)
          .attr("y1", (d, i) => marginTop + yTickSpace * i)
          .attr("x2", (d, i) => marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i)
          .attr("class", "y_tick")
          .attr("stroke", "#F0F0F0")
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => width - marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i);

        const columnLine = svg
          .append("g")
          .attr("class", "x_tick_group")
          .selectAll("line")
          .data(xTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft + xTickSpace * i)
          .attr("y1", (d, i) => height - marginBottom)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => height - marginBottom)
          .attr("class", "x_tick")
          .attr("stroke", "#F0F0F0") //F0F0F0
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => marginTop);
      };
      drawGrid();

      //画标题
      const drawTitle = async () => {
        const titleGroup = svg.append("g").attr("class", "title_text");
        // titleGroup.append("rect").attr("x", marginLeft).attr("y", marginTop)
        const macdText = titleGroup
          .append("text")
          .attr("x", marginLeft + 5)
          .attr("y", marginTop)
          .text("MACD（12，26，9）")
          .attr("fill", "#161616")
          .attr("class", "macd_text")
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);

        await sleep(0);
        let macdTextWidth = macdText.node().getComputedTextLength();

        console.log('timeData',timeData);

        const diffText = titleGroup
          .append("text")
          .attr("x", marginLeft + macdTextWidth + 10)
          .attr("y", marginTop)
          .text("DIF:" + timeData[timeData.length - 1].macd.diff.toFixed(2))
          .attr("class", "diff_text")
          .attr("fill", "#FFC100")
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);

        await sleep(0);
        let diffTextWidth = diffText.node().getComputedTextLength();

        const deaText = titleGroup
          .append("text")
          .attr("x", marginLeft + macdTextWidth + diffTextWidth + 15)
          .attr("y", marginTop)
          .text("DEA:" + timeData[timeData.length - 1].macd.dea.toFixed(2))
          .attr("class", "dea_text")
          .attr("fill", "#1496FF")
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);

        await sleep(0);
        let deaTextWidth = deaText.node().getComputedTextLength();
        console.log(deaText.node().getBBox());
        const macdText2 = titleGroup
          .append("text")
          .attr(
            "x",
            marginLeft + macdTextWidth + diffTextWidth + deaTextWidth + 20
          )
          .attr("y", marginTop)
          .text("M:" + timeData[timeData.length - 1].macd.macd.toFixed(2))
          .attr("class", "macd_text2")
          .attr("fill", "#FF1CA4")
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);

        // console.log(macdTextWidth, deaTextWidth, deaTextWidth);
      };
      drawTitle();

      //画macd柱子
      const drawBar = () => {
        const macdBarGroup = svg.append("g").attr("class", "macd_bar_group");
        const macdBar = macdBarGroup
          .selectAll("rect")
          .data(timeData)
          .enter()
          .append("rect")
          .attr("x", (d, i) => xScale(d.label))
          .attr("y", (d, i) =>
            d.macd.macd > 0 ? yScale(d.macd.macd) : yScale(0)
          )
          .attr("width", (d, i) => xScale.bandwidth())
          .attr("height", (d, i) => Math.abs(yScale(0) - yScale(d.macd.macd)))
          .attr("class", (d) =>
            d.macd.macd > 0 ? "macd macd_positive" : "macd macd_negative"
          )
          .attr("fill", (d) =>
            d.macd.macd > 0 ? "#FF3B30" : d.macd.macd <= 0 ? "#18AA0C" : "#666"
          )
          // .attr("stroke-width", xScale.bandwidth())
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attrTween("opacity", (d, i, a) => {
            let index = d3.interpolate(0, timeData.length - 1);
            return (t) => {
              let num = Math.round(index(t));
              return i <= num ? 1 : 0;
            };
          });
      };
      drawBar();

      //画量diff和dea线
      const drawLine = () => {
        const lineData = [
          timeData.map((d) => d.macd.diff),
          timeData.map((d) => d.macd.dea),
        ];
        const macdLineGroup = svg.append("g").attr("class", "macd_line_group");

        for (let j = 0; j < 2; j++) {
          const Y = lineData[j];
          const pathLine = d3
            .line()
            .curve(curve)
            .x((i) => xScale(X[i]))
            .y((i) => yScale(Y[i]));

          const macdLine = macdLineGroup
            .append("path")
            .attr("fill", "none")
            .attr("class", (d) => (j == 0 ? `line diff_line` : "line dea_line"))
            .attr("stroke", (d) => (j == 0 ? "#FFC100" : "#1496FF"))
            // .attr("stroke-width", "1")
            .attr("d", pathLine(d3.range(Y.length)));
          const svgLineTotalLength = macdLine.node().getTotalLength();
          macdLine
            .attr(
              "stroke-dasharray",
              svgLineTotalLength + "," + svgLineTotalLength
            )
            .attr("stroke-dashoffset", svgLineTotalLength)
            .transition()
            .duration(duration)
            .ease(d3[ease])
            .attr("stroke-dashoffset", 0);
        }
      };
      drawLine();

      //画x轴时间
      const drawXAxisTime = () => {
        if (isTicks) {
          if (dataLen < 15) {
            xTicks.push(X[0]);
          } else if (dataLen >= 15 && dataLen <= 30) {
            xTicks.push(X[0]);
            xTicks.push(X[X.length - 1]);
          } else if (dataLen > 30) {
            xTicks.push(X[0]);
            xTicks.push(X[Math.floor(X.length / 2)]);
            xTicks.push(X[X.length - 1]);
          }
        }
        const xAxisGroup = svg
          .append("g")
          .attr("class", "x_axis_group")
          .selectAll("text")
          .data(xTicks)
          .enter()
          .append("text")
          .attr("fill", "currentColor")
          .attr("x", (d, i) => {
            if (i == 0) {
              return marginLeft;
            } else if (i == xTicks.length - 1) {
              return width - marginRight;
            } else {
              return (
                marginLeft +
                ((width - marginRight - marginLeft) / (xTicks.length - 1)) * i
              );
            }
          })
          .attr("y", height - marginBottom)
          .text((d) => d)
          .attr("dy", "1.2em")
          .attr("text-anchor", (d, i) => {
            if (i == 0) {
              return "start";
            } else if (i == xTicks.length - 1) {
              return "end";
            } else {
              return "middle";
            }
          })
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
      };
      drawXAxisTime();

      $("#" + this.id).html(svg.node());
    },

    //分时资金博弈
    async fundsFight(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop + 20], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        totalCount = 241,
        xTicks = [],
      } = {}
    ) {
      this.duration = duration;

      const curve = d3[curveName];
      const timeData = data.data;
      const X = timeData.map((d) => d.label);
      console.log(timeData);
      const yMaxArr = [
        d3.max(timeData, (d) => d.fundFight?d.fundFight.big:0),
        d3.max(timeData, (d) => d.fundFight?d.fundFight.mid:0),
        d3.max(timeData, (d) => d.fundFight?d.fundFight.small:0),
        d3.max(timeData, (d) => d.fundFight?d.fundFight.super:0),
      ];
      const yMinArr = [
        d3.min(timeData, (d) => d.fundFight?d.fundFight.big:0),
        d3.min(timeData, (d) => d.fundFight?d.fundFight.mid:0),
        d3.min(timeData, (d) => d.fundFight?d.fundFight.small:0),
        d3.min(timeData, (d) => d.fundFight?d.fundFight.super:0),
      ];

      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [d3.min(yMinArr), d3.max(yMaxArr)];

      xRange = [
        marginLeft,
        ((width - marginRight - marginLeft) * timeData.length) / totalCount +
          marginLeft,
      ];
      const xScale = d3.scalePoint(xDomain, xRange);
      const yScale = d3.scaleLinear(yDomain, yRange);
      const yMagnify = d3.scaleLinear().domain(yRange).range(yDomain); //计算y轴坐标和value对应关系 yMagnify(y)=>value

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      const yTick = [1, 2, 3];
      const xTick = [1, 2, 3, 4, 5];
      const yTickSpace =
        (height - marginTop - marginBottom) / (yTick.length - 1);
      const xTickSpace =
        (width - marginLeft - marginRight) / (xTick.length - 1);
      //画网格
      const drawGrid = () => {
        const rowLine = svg
          .append("g")
          .attr("class", "y_tick_group")
          .selectAll("line")
          .data(yTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft)
          .attr("y1", (d, i) => marginTop + yTickSpace * i)
          .attr("x2", (d, i) => marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i)
          .attr("class", "y_tick")
          .attr("stroke", "#F0F0F0")
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => width - marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i);

        const columnLine = svg
          .append("g")
          .attr("class", "x_tick_group")
          .selectAll("line")
          .data(xTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft + xTickSpace * i)
          .attr("y1", (d, i) => height - marginBottom)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => height - marginBottom)
          .attr("class", "x_tick")
          .attr("stroke", "#F0F0F0") //F0F0F0
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => marginTop);
      };
      drawGrid();

      //画标题
      const drawTitle = async () => {
        const titleGroup = svg.append("g").attr("class", "title_text");

        const fundText = titleGroup
          .append("text")
          .attr("x", marginLeft + 5)
          .attr("y", marginTop)
          .text("资金博弈")
          .attr("fill", "#161616")
          .attr("class", "fund_text")
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);

        await sleep(0);
        let fundTextWidth = fundText.node().getComputedTextLength();

        const superText = titleGroup
          .append("text")
          .attr("x", marginLeft + fundTextWidth + 10)
          .attr("y", marginTop)
          .text(
            "超:" + timeData[timeData.length - 1].fundFight.super.toFixed(2)
          )
          .attr("class", "super_text")
          .attr("fill", "#FF3B30")
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);

        await sleep(0);
        let superTextWidth = superText.node().getComputedTextLength();

        const bigText = titleGroup
          .append("text")
          .attr("x", marginLeft + fundTextWidth + superTextWidth + 15)
          .attr("y", marginTop)
          .text("大:" + timeData[timeData.length - 1].fundFight.big.toFixed(2))
          .attr("class", "big_text")
          .attr("fill", "#FFC100")
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);

        await sleep(0);
        let bigTextWidth = bigText.node().getComputedTextLength();

        const midText = titleGroup
          .append("text")
          .attr(
            "x",
            marginLeft + fundTextWidth + superTextWidth + bigTextWidth + 20
          )
          .attr("y", marginTop)
          .text("中:" + timeData[timeData.length - 1].fundFight.mid.toFixed(2))
          .attr("class", "mid_text")
          .attr("fill", "#3C88D4")
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);

        await sleep(0);
        let midTextWidth = midText.node().getComputedTextLength();

        const smallText = titleGroup
          .append("text")
          .attr(
            "x",
            marginLeft +
              fundTextWidth +
              superTextWidth +
              bigTextWidth +
              midTextWidth +
              25
          )
          .attr("y", marginTop)
          .text(
            "中:" + timeData[timeData.length - 1].fundFight.small.toFixed(2)
          )
          .attr("class", "small_text")
          .attr("fill", "#39B24E")
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
      };
      drawTitle();

      //画线
      const drawLine = () => {
        const lineData = [
          timeData.map((d) => d.fundFight?d.fundFight.super:0),
          timeData.map((d) => d.fundFight?d.fundFight.big:0),
          timeData.map((d) => d.fundFight?d.fundFight.mid:0),
          timeData.map((d) => d.fundFight?d.fundFight.small:0),
        ];

        for (let j = 0; j < lineData.length; j++) {
          const Y = lineData[j];
          const pathLine = d3
            .line()
            .curve(curve)
            .x((i) => xScale(X[i]))
            .y((i) => yScale(Y[i]));

          const fundLine = svg
            .append("g")
            .attr("class", "ratio_line")
            .append("path")
            .attr("fill", "none")
            .attr("class", "line")
            .attr("stroke", () => {
              if (j === 0) {
                return "#FF3B30";
              } else if (j === 2) {
                return "#FFC100";
              } else if (j === 3) {
                return "#3C88D4";
              } else {
                return "#39B24E";
              }
            })
            .attr("stroke-width", "1")
            .attr("d", pathLine(d3.range(Y.length)));
          const svgLineTotalLength = fundLine.node().getTotalLength();
          fundLine
            .attr(
              "stroke-dasharray",
              svgLineTotalLength + "," + svgLineTotalLength
            )
            .attr("stroke-dashoffset", svgLineTotalLength)
            .transition()
            .duration(duration)
            .ease(d3[ease])
            .attr("stroke-dashoffset", 0);
        }
      };
      drawLine();

      //画x轴时间
      const drawXAxisTime = () => {
        const xAxisGroup = svg
          .append("g")
          .attr("class", "x_axis_group")
          .selectAll("text")
          .data(xTicks)
          .enter()
          .append("text")
          .attr("fill", "currentColor")
          .attr("x", (d, i) => {
            if (i == 0) {
              return marginLeft;
            } else if (i == xTicks.length - 1) {
              return width - marginRight;
            } else {
              return (
                marginLeft +
                ((width - marginRight - marginLeft) / (xTicks.length - 1)) * i
              );
            }
          })
          .attr("y", height - marginBottom)
          .text((d) => d)
          .attr("dy", "1.2em")
          .attr("text-anchor", (d, i) => {
            if (i == 0) {
              return "start";
            } else if (i == xTicks.length - 1) {
              return "end";
            } else {
              return "middle";
            }
          })
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
      };
      drawXAxisTime();

      $("#" + this.id).html(svg.node());
    },

    //k线成交量
    async kVolumeChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop + 20], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        xPadding = 0.3,
        xTicks = false,
      } = {}
    ) {
      this.duration = duration;
      // data = data.reverse();
      const dataLen = data.length;
      const curve = d3[curveName];
      const X = data.map((d) => d.label);
      const yMax = d3.max(data, (d) => Math.abs(d.volume));

      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [0, yMax];

      if (data.length < 60) {
        xRange = [
          marginLeft,
          ((width - marginRight - marginLeft) * data.length) / 60 + marginLeft,
        ];
      }

      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      const yTick = [1, 2, 3];
      const xTick = [1, 2, 3, 4, 5];
      const yTickSpace =
        (height - marginTop - marginBottom) / (yTick.length - 1);
      const xTickSpace =
        (width - marginLeft - marginRight) / (xTick.length - 1);
      //画网格
      const drawGrid = () => {
        const rowLine = svg
          .append("g")
          .attr("class", "y_tick_group")
          .selectAll("line")
          .data(yTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft)
          .attr("y1", (d, i) => marginTop + yTickSpace * i)
          .attr("x2", (d, i) => marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i)
          .attr("class", "y_tick")
          .attr("stroke", "#F0F0F0")
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => width - marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i);

        const columnLine = svg
          .append("g")
          .attr("class", "x_tick_group")
          .selectAll("line")
          .data(xTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft + xTickSpace * i)
          .attr("y1", (d, i) => height - marginBottom)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => height - marginBottom)
          .attr("class", "x_tick")
          .attr("stroke", "#F0F0F0") //F0F0F0
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => marginTop);
      };
      drawGrid();

      //画标题
      const drawTitle = async () => {
        const titleGroup = svg.append("g").attr("class", "title_text");

        const volumeText = titleGroup
          .append("text")
          .attr("class", "volume_text")
          .attr("fill", "currentColor")
          .attr("x", marginLeft + 5)
          .attr("y", marginTop)
          .text(
            "成交量 " +
              (Math.abs(data[data.length - 1].volume) / 1000000).toFixed(2) +
              "万"
          )
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
        await sleep(0);
        let volumeTextWidth = volumeText.node().getComputedTextLength();

        const ma5Text = titleGroup
          .append("text")
          .attr("class", "ma5_text")
          .attr("x", marginLeft + volumeTextWidth + 10)
          .attr("y", marginTop)
          .text(
            "MA5:" +
              (Math.abs(data[data.length - 1].volumeAve[0]) / 1000000).toFixed(
                2
              ) +
              "万"
          )
          .attr("dy", "1.2em")
          .attr("fill", "#FF1CA4")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
        await sleep(0);
        let ma5TextWidth = ma5Text.node().getComputedTextLength();

        const ma10Text = titleGroup
          .append("text")
          .attr("class", "ma10_text")
          .attr("x", marginLeft + volumeTextWidth + ma5TextWidth + 15)
          .attr("y", marginTop)
          .text(
            "MA10:" +
              (Math.abs(data[data.length - 1].volumeAve[1]) / 1000000).toFixed(
                2
              ) +
              "万"
          )
          .attr("dy", "1.2em")
          .attr("fill", "#FFC100")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
      };
      drawTitle();

      //画柱子
      const drawBar = () => {
        const barGroup = svg
          .append("g")
          .attr("class", "k_bars_group")
          .selectAll("rect")
          .data(data)
          .enter()
          .append("rect")
          .attr("class", (d, i) =>
            d.volume > 0 ? "k_bar k_bar_positive" : "k_bar k_bar_negative"
          )
          .attr("x", (d, i) => xScale(d.label))
          .attr("y", (d, i) => yScale(Math.abs(d.volume)))
          .attr("width", xScale.bandwidth())
          .attr("height", (d, i) => yScale(0) - yScale(Math.abs(d.volume)))
          .attr("fill", (d) => (d.volume > 0 ? "transparent" : "#18AA0C"))
          .attr("stroke", (d) => (d.volume > 0 ? "#FF3B30" : "#18AA0C"))
          .attr("stroke-width", 1)
          .attr("opacity", 0)
          .transition()
          .delay((d, i) => i * delay)
          .duration(duration)
          .ease(d3[ease])
          .attr("opacity", 1);
      };
      drawBar();

      //画线
      const drawLine = () => {
        const lineData = [
          data.map((d) => d.volumeAve[0]),
          data.map((d) => d.volumeAve[1]),
        ];
        for (let j = 0; j < lineData.length; j++) {
          const Y = lineData[j];
          const pathLine = d3
            .line()
            .defined((d, i) => {
              return Y[i] != null;
            })
            .curve(curve)
            .x((i) => xScale(X[i]) + xScale.bandwidth() / 2)
            .y((i) => yScale(Y[i]));

          const volumeLine = svg
            .append("g")
            .attr("class", "volume_line")
            .append("path")
            .attr("fill", "none")
            .attr("class", "line")
            .attr("stroke", () => (j === 0 ? "#FF1CA4" : "#FFC100"))
            .attr("stroke-width", "1")
            .attr("d", pathLine(d3.range(Y.length)));
          const svgLineTotalLength = volumeLine.node().getTotalLength();
          volumeLine
            .attr(
              "stroke-dasharray",
              svgLineTotalLength + "," + svgLineTotalLength
            )
            .attr("stroke-dashoffset", svgLineTotalLength)
            .transition()
            .duration((data.length - 1) * delay)
            .ease(d3[ease])
            .attr("stroke-dashoffset", 0);
        }
      };
      drawLine();

      //画x轴时间
      const drawXAxisTime = () => {
        const xArr = [];
        if (dataLen < 15) {
          xArr.push(X[0]);
        } else if (dataLen >= 15 && dataLen <= 30) {
          xArr.push(X[0]);
          xArr.push(X[X.length - 1]);
        } else if (dataLen > 30) {
          xArr.push(X[0]);
          xArr.push(X[Math.floor(X.length / 2)]);
          xArr.push(X[X.length - 1]);
        }
        console.log(xArr);
        const xAxisGroup = svg
          .append("g")
          .attr("class", "x_axis_group")
          .selectAll("text")
          .data(xArr)
          .enter()
          .append("text")
          .attr("fill", "currentColor")
          .attr("x", (d, i) => {
            return i == xArr.length - 1 && xArr.length > 1
              ? xScale(d) + xScale.bandwidth()
              : xScale(d);
          })
          .attr("y", height - marginBottom)
          .text((d) => d)
          .attr("dy", "1.1em")
          .attr("text-anchor", (d, i) => {
            if (i == 0) {
              return "start";
            } else if (i == xArr.length - 1) {
              return "end";
            } else {
              return "middle";
            }
          })
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .delay((d, i) => i * delay * Math.floor(X.length / 2))
          .attr("opacity", 1);
      };
      if (xTicks) {
        drawXAxisTime();
      }

      $("#" + this.id).html(svg.node());
    },

    //k线主力资金
    async kCapitalChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop + 20], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //
        xPadding = 0.3,
        xTicks = false,
      } = {}
    ) {
      this.duration = duration;
      // data = data.reverse();
      // console.log(data);
      const dataLen = data.length;
      const curve = d3[curveName];
      const X = data.map((d) => d.label);
      const yMaxArr = [
        d3.max(data, (d) => d.capital),
        d3.max(data, (d) => d.capitalAve[0]),
        d3.max(data, (d) => d.capitalAve[1]),
      ];
      const yMinArr = [
        d3.min(data, (d) => d.capital),
        d3.min(data, (d) => d.capitalAve[0]),
        d3.min(data, (d) => d.capitalAve[1]),
      ];

      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [d3.min(yMinArr), d3.max(yMaxArr)];

      if (data.length < 60) {
        xRange = [
          marginLeft,
          ((width - marginRight - marginLeft) * data.length) / 60 + marginLeft,
        ];
      }

      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);
      const yMagnify = d3.scaleLinear().domain(yRange).range(yDomain); //计算y轴坐标和value对应关系 yMagnify(y)=>value

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      const yTick = [1, 2, 3];
      const xTick = [1, 2, 3, 4, 5];
      const yTickSpace =
        (height - marginTop - marginBottom) / (yTick.length - 1);
      const xTickSpace =
        (width - marginLeft - marginRight) / (xTick.length - 1);
      //画网格
      const drawGrid = () => {
        const rowLine = svg
          .append("g")
          .attr("class", "y_tick_group")
          .selectAll("line")
          .data(yTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft)
          .attr("y1", (d, i) => marginTop + yTickSpace * i)
          .attr("x2", (d, i) => marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i)
          .attr("class", "y_tick")
          .attr("stroke", "#F0F0F0")
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => width - marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i);

        const columnLine = svg
          .append("g")
          .attr("class", "x_tick_group")
          .selectAll("line")
          .data(xTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft + xTickSpace * i)
          .attr("y1", (d, i) => height - marginBottom)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => height - marginBottom)
          .attr("class", "x_tick")
          .attr("stroke", "#F0F0F0") //F0F0F0
          .attr("stroke-width", 1)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => marginTop);
      };
      drawGrid();

      //画标题
      const drawTitle = async () => {
        const titleGroup = svg.append("g").attr("class", "title_text");

        const capitalText = titleGroup
          .append("text")
          .attr("class", "capital_text")
          .attr("fill", "currentColor")
          .attr("x", marginLeft + 5)
          .attr("y", marginTop)
          .text(
            "主力资金 净量(亿):" +
              (data[data.length - 1].capital / Math.pow(10, 8)).toFixed(2)
          )
          .attr("dy", "1.2em")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
        await sleep(0);
        let capitalTextWidth = capitalText.node().getComputedTextLength();

        const ma5Text = titleGroup
          .append("text")
          .attr("class", "ma5_text")
          .attr("x", marginLeft + capitalTextWidth + 10)
          .attr("y", marginTop)
          .text(
            "MA5:" +
              (data[data.length - 1].capitalAve[0] / Math.pow(10, 8)).toFixed(2)
          )
          .attr("dy", "1.2em")
          .attr("fill", "#FF1CA4")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
        await sleep(0);
        let ma5TextWidth = ma5Text.node().getComputedTextLength();

        const ma10Text = titleGroup
          .append("text")
          .attr("class", "ma10_text")
          .attr("x", marginLeft + capitalTextWidth + ma5TextWidth + 15)
          .attr("y", marginTop)
          .text(
            "MA10:" +
              (data[data.length - 1].capitalAve[1] / Math.pow(10, 8)).toFixed(2)
          )
          .attr("dy", "1.2em")
          .attr("fill", "#FFC100")
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .attr("opacity", 1);
      };
      drawTitle();

      //画柱子
      const drawBar = () => {
        const barGroup = svg
          .append("g")
          .attr("class", "k_bars_group")
          .selectAll("rect")
          .data(data)
          .enter()
          .append("rect")
          .attr("class", (d, i) =>
            d.capital > 0 ? "k_bar k_bar_positive" : "k_bar k_bar_negative"
          )
          .attr("x", (d, i) => xScale(d.label))
          .attr("y", (d, i) => (d.capital > 0 ? yScale(d.capital) : yScale(0)))
          .attr("width", xScale.bandwidth())
          .attr("height", (d, i) => Math.abs(yScale(0) - yScale(d.capital)))
          .attr("fill", (d) => (d.capital > 0 ? "#FF3B30" : "#18AA0C"))
          .attr("stroke", (d) => (d.capital > 0 ? "#FF3B30" : "#18AA0C"))
          .attr("stroke-width", 1)
          .attr("opacity", 0)
          .transition()
          .delay((d, i) => i * delay)
          .duration(duration)
          .ease(d3[ease])
          .attr("opacity", 1);
      };
      drawBar();

      //画线
      const drawLine = () => {
        const lineData = [
          data.map((d) => d.capitalAve[0]),
          data.map((d) => d.capitalAve[1]),
        ];
        for (let j = 0; j < lineData.length; j++) {
          const Y = lineData[j];
          const pathLine = d3
            .line()
            .curve(curve)
            .x((i) => xScale(X[i]) + xScale.bandwidth() / 2)
            .y((i) => yScale(Y[i]));

          const volumeLine = svg
            .append("g")
            .attr("class", "volume_line")
            .append("path")
            .attr("fill", "none")
            .attr("class", "line")
            .attr("stroke", () => (j === 0 ? "#FF1CA4" : "#FFC100"))
            .attr("stroke-width", "1")
            .attr("d", pathLine(d3.range(Y.length)));
          const svgLineTotalLength = volumeLine.node().getTotalLength();
          volumeLine
            .attr(
              "stroke-dasharray",
              svgLineTotalLength + "," + svgLineTotalLength
            )
            .attr("stroke-dashoffset", svgLineTotalLength)
            .transition()
            .duration((data.length - 1) * delay)
            .ease(d3[ease])
            .attr("stroke-dashoffset", 0);
        }
      };
      drawLine();

      //画x轴时间
      const drawXAxisTime = () => {
        const xArr = [];
        if (dataLen < 15) {
          xArr.push(X[0]);
        } else if (dataLen >= 15 && dataLen <= 30) {
          xArr.push(X[0]);
          xArr.push(X[X.length - 1]);
        } else if (dataLen > 30) {
          xArr.push(X[0]);
          xArr.push(X[Math.floor(X.length / 2)]);
          xArr.push(X[X.length - 1]);
        }
        console.log(xArr);
        const xAxisGroup = svg
          .append("g")
          .attr("class", "x_axis_group")
          .selectAll("text")
          .data(xArr)
          .enter()
          .append("text")
          .attr("fill", "currentColor")
          .attr("x", (d, i) => {
            return i == xArr.length - 1 && xArr.length > 1
              ? xScale(d) + xScale.bandwidth()
              : xScale(d);
          })
          .attr("y", height - marginBottom)
          .text((d) => d)
          .attr("dy", "1.1em")
          .attr("text-anchor", (d, i) => {
            if (i == 0) {
              return "start";
            } else if (i == xArr.length - 1) {
              return "end";
            } else {
              return "middle";
            }
          })
          .attr("opacity", 0)
          .transition()
          .duration(duration)
          .delay((d, i) => i * delay * Math.floor(X.length / 2))
          .attr("opacity", 1);
      };
      if (xTicks) {
        drawXAxisTime();
      }

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
